import { toFloat, getFixedResult } from '@/helpers/numbers'

const FEES = {
  percentage: 2.9,
  value: 0.3
}

const getValueFee = value => (value * FEES.percentage) / 100 + FEES.value

export const calculateStripeFees = value => {
  const parsedValue = toFloat(value)

  if (!parsedValue) {
    return {
      totalFees: 0,
      valueToReceive: 0,
      valueToAsk: 0,
      valueToAskFee: 0
    }
  }

  const totalFees = getValueFee(parsedValue)
  const valueToReceive = value - totalFees
  const valueToAsk = (parsedValue + FEES.value) / (1 - FEES.percentage / 100)
  const valueToAskFee = getValueFee(valueToAsk)

  return {
    totalFees: toFloat(getFixedResult(totalFees)),
    valueToReceive: toFloat(getFixedResult(valueToReceive)),
    valueToAsk: toFloat(getFixedResult(valueToAsk)),
    valueToAskFee: toFloat(getFixedResult(valueToAskFee))
  }
}
