import { AUCTION_UPGRADES_TYPES, STORE_TYPES, TYPES, UPGRADE_VALUE_REFERENCE } from './constants'

const getValueUpgradesFee = (value, upgrades, insertionFee) => {
  const getFee = fees => {
    if (typeof fees === 'function') {
      return fees(value)
    }

    return value <= UPGRADE_VALUE_REFERENCE ? fees.lower : fees.higher
  }

  const valueUpgradesFee = upgrades.reduce((prev, curr) => prev + getFee(curr.fees, curr.type), 0)

  if (upgrades.find(({ type }) => type === AUCTION_UPGRADES_TYPES.TWO_CATEGORIES)) {
    return (valueUpgradesFee + insertionFee) * 2
  }

  return valueUpgradesFee + insertionFee
}

const getFinalValueFee = (buyItNowPrice, quantity, shippingPrice, category, store) => {
  const {
    fees: {
      finalValue: { percentage, maximumFee },
      stores
    }
  } = category

  const finalValue = buyItNowPrice * quantity + shippingPrice

  if (store.type !== STORE_TYPES.NONE) {
    const {
      [store.type]: { percentage: percentageStore, maximumFee: maximumFeeStore }
    } = stores

    const finalValueFee = (finalValue * percentageStore) / 100

    return finalValueFee > maximumFeeStore ? maximumFeeStore : finalValueFee
  }

  const finalValueFee = (finalValue * percentage) / 100

  return finalValueFee > maximumFee ? maximumFee : finalValueFee
}

const getInsertionFee = (store, calculationType, categoryInsertionFee) => {
  if (store.insertionFee[calculationType] === 0) {
    return categoryInsertionFee
  }

  return store.insertionFee[calculationType]
}

export const calculateFixedPriceFee = ({ values, freeInsertionFee, category, upgrades, store }) => {
  const insertionFee = freeInsertionFee
    ? 0
    : getInsertionFee(store, TYPES.FIXED_PRICE, category.fees.insertion)

  const finalValueFee = getFinalValueFee(
    values.buyItNowPrice,
    values.quantity,
    values.shippingPrice,
    category,
    store
  )

  const valueUpgradesFee = getValueUpgradesFee(values.buyItNowPrice, upgrades, insertionFee)

  const totalFees = finalValueFee + valueUpgradesFee

  return +totalFees.toFixed(2)
}
