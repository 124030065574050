import React, { useState } from 'react'

import {
  Grid,
  Input,
  Dropdown,
  Separator,
  Label,
  Toggle,
  Accordion,
  Button,
  Formula
} from '@/style-guide'
import {
  lightBlueOpaque,
  semiDarkBlueOpaque,
  lightBlue,
  semiDarkBlue,
  blue,
  blueOpaque,
  orange,
  orangeOpaque,
  purple,
  purpleOpaque
} from '@/style-guide/colors'

import { formatObjectValuesToNumber } from '@/helpers/numbers'
import { number } from '@/helpers/validations'
import { useFormCalculator } from '@/hooks/useFormCalculator'
import { TYPES, CATEGORIES, STORES, AUCTION_UPGRADES, FIXED_UPGRADES } from './constants'

import { CalculatorCard, ResultCard, DoughnutChart } from '../../components'

import { calculateFees } from './calculations'
import { OPTIONS_FEES } from '../PayPal/dropdownOptions'

const auctionValues = {
  closingPrice: '',
  buyItNowPrice: '',
  shippingPrice: '',
  startingPrice: '',
  reservePrice: '',
  itemCost: '',
  shippingCost: ''
}

const fixedPriceValues = {
  buyItNowPrice: '',
  shippingPrice: '',
  quantity: '1',
  itemCost: '',
  shippingCost: ''
}

const calculationTypes = [
  {
    key: 'Auction',
    value: TYPES.AUCTION
  },
  {
    key: 'Fixed',
    value: TYPES.FIXED_PRICE
  }
]

const PAYPAL_OPTIONS = [
  {
    key: 'Not using PayPal',
    value: 0,
    costPerTransaction: 0
  },
  ...OPTIONS_FEES
]

const FinalFee = () => {
  const [eBayFee, setEBayFee] = useState('')
  const [returnValue, setReturnValue] = useState('')
  const [saleValues, setSaleValues] = useState(auctionValues)
  const [calculatorType, setCalculatorType] = useState(calculationTypes[0])
  const [chartData, setChartData] = useState([])
  const isAuctionTypeSelected = calculatorType.value === TYPES.AUCTION

  const { formValues, formValid, resetValues, changeValue } = useFormCalculator({
    values: {
      type: calculatorType,
      ...saleValues,
      itemCost: '',
      shippingCost: '',
      freeInsertionFee: false,
      category: CATEGORIES[0],
      paypalPayment: PAYPAL_OPTIONS[0],
      store: STORES[0],
      upgrades: []
    },
    requiredKeys: [isAuctionTypeSelected ? 'closingPrice' : 'buyItNowPrice'],
    validation: {
      closingPrice: number.positiveNumber,
      buyItNowPrice: number.positiveNumber,
      shippingPrice: number.positiveNumber,
      startingPrice: number.positiveNumber,
      reservePrice: number.positiveNumber,
      quantity: number.positiveNumber,
      itemCost: number.positiveNumber,
      shippingCost: number.positiveNumber
    }
  })

  const UPGRADE_OPTIONS = isAuctionTypeSelected ? AUCTION_UPGRADES : FIXED_UPGRADES

  const handleChangeUpgrade = (checked, value) => {
    if (!checked) {
      changeValue(
        'upgrades',
        formValues.upgrades.filter(({ type }) => type !== value.type)
      )
    } else {
      changeValue('upgrades', [...formValues.upgrades, value])
    }
  }

  const handleCalculate = () => {
    const values = Object.keys(saleValues).reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: formValues[curr]
      }),
      {}
    )

    const { eBayFee, paypalFee, profitValue, returnValue } = calculateFees({
      ...formValues,
      type: formValues.type.value,
      values: formatObjectValuesToNumber(values)
    })

    setChartData([profitValue, eBayFee, paypalFee, formValues.itemCost, formValues.shippingCost])
    setEBayFee(eBayFee)
    setReturnValue(returnValue)
  }

  const handleReset = () => {
    resetValues()
    setEBayFee('')
    setReturnValue('')
    setChartData([])
    setSaleValues(auctionValues)
    setCalculatorType(calculationTypes[0])
  }

  const handleChangeType = type => {
    handleReset()
    changeValue('type', type)
    setCalculatorType(type)

    if (type.value === TYPES.FIXED_PRICE) {
      setSaleValues(fixedPriceValues)
    } else {
      setSaleValues(auctionValues)
    }
  }
  const renderSales = () => (
    <>
      <Separator>Sale</Separator>
      <Grid>
        {formValues.type.value === TYPES.AUCTION ? (
          <>
            <Grid.Row>
              <Grid.Col>
                <Input
                  key="auction.closingPrice"
                  type="number"
                  label="Closing price"
                  value={formValues.closingPrice}
                  onChange={value => changeValue('closingPrice', value)}
                />
              </Grid.Col>
              <Grid.Col>
                <Input
                  key="auction.buyItNowPrice"
                  type="number"
                  label="Buy it now price"
                  value={formValues.buyItNowPrice}
                  onChange={value => changeValue('buyItNowPrice', value)}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Input
                  key="auction.shippingPrice"
                  type="number"
                  label="Shipping price"
                  value={formValues.shippingPrice}
                  onChange={value => changeValue('shippingPrice', value)}
                />
              </Grid.Col>
              <Grid.Col>
                <Input
                  key="auction.startingPrice"
                  type="number"
                  label="Starting price"
                  value={formValues.startingPrice}
                  onChange={value => changeValue('startingPrice', value)}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col style={{ maxWidth: '50%' }}>
                <Input
                  key="auction.reservePrice"
                  type="number"
                  label="Reserve price"
                  value={formValues.reservePrice}
                  onChange={value => changeValue('reservePrice', value)}
                />
              </Grid.Col>
            </Grid.Row>
          </>
        ) : (
          <>
            <Grid.Row>
              <Grid.Col>
                <Input
                  key="fixed.buyItNowPrice"
                  type="number"
                  label="Buy it now price"
                  value={formValues.buyItNowPrice}
                  onChange={value => changeValue('buyItNowPrice', value)}
                />
              </Grid.Col>
              <Grid.Col>
                <Input
                  key="fixed.shippingPrice"
                  type="number"
                  label="Shipping price"
                  value={formValues.shippingPrice}
                  onChange={value => changeValue('shippingPrice', value)}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col style={{ maxWidth: '50%' }}>
                <Input
                  key="fixed.quantity"
                  type="number"
                  min="0"
                  label="Quantity"
                  value={formValues.quantity}
                  onChange={value => changeValue('quantity', value)}
                />
              </Grid.Col>
            </Grid.Row>
          </>
        )}
      </Grid>
    </>
  )

  const renderCosts = () => (
    <Accordion
      title="Costs"
      content={
        <Grid>
          <Grid.Row>
            <Grid.Col>
              <Input
                label="Item cost"
                type="number"
                value={formValues.itemCost}
                onChange={value => changeValue('itemCost', value)}
              />
            </Grid.Col>
            <Grid.Col>
              <Input
                label="Shipping cost"
                type="number"
                value={formValues.shippingCost}
                onChange={value => changeValue('shippingCost', value)}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      }
    />
  )

  const renderListing = () => (
    <Accordion
      title="Listing"
      opened={false}
      content={
        <Grid>
          <Grid.Row>
            <Grid.Col>
              <Label light>Category</Label>
              <Dropdown
                options={CATEGORIES}
                value={formValues.category}
                labelKey="label"
                valueKey="fees"
                onChange={value => changeValue('category', value)}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <Label light>Store</Label>
              <Dropdown
                options={STORES}
                value={formValues.store}
                labelKey="label"
                valueKey="fees"
                onChange={value => changeValue('store', value)}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <Toggle
                label="Free insertion Fee"
                value={formValues.freeInsertionFee}
                checked={formValues.freeInsertionFee}
                onChange={checked => changeValue('freeInsertionFee', checked)}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      }
    />
  )

  const renderPayment = () => (
    <Accordion
      title="Payment"
      opened={false}
      content={
        <Grid>
          <Grid.Row>
            <Grid.Col>
              <Label light>PayPal Rate</Label>
              <Dropdown
                value={formValues.paypalPayment}
                options={PAYPAL_OPTIONS}
                onChange={value => changeValue('paypalPayment', value)}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      }
    />
  )

  const renderUpgrades = () => (
    <Accordion
      title="Upgrades"
      opened={false}
      content={
        <Grid>
          <Grid.Row>
            <Grid.Col>
              {UPGRADE_OPTIONS.map(upgrade => (
                <Toggle
                  key={upgrade.label}
                  value={upgrade}
                  checked={!!formValues.upgrades.find(({ type }) => type === upgrade.type)}
                  onChange={handleChangeUpgrade}
                  label={upgrade.label}
                />
              ))}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      }
    />
  )

  const renderResult = () => (
    <Grid>
      <Grid.Row>
        <Grid.Col style={{ alignItems: 'center' }}>
          <DoughnutChart
            data={chartData}
            labels={['Total Profit', 'eBay fees', 'PayPal fee', 'Item cost', 'Shipping cost']}
            middleValue={`$${eBayFee}`}
            backgroundColor={[
              lightBlueOpaque,
              semiDarkBlueOpaque,
              blueOpaque,
              orangeOpaque,
              purpleOpaque
            ]}
            hoverBackgroundColor={[lightBlue, semiDarkBlue, blue, orange, purple]}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <Formula title="Rate of Return">{returnValue}%</Formula>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  )

  return (
    <>
      <CalculatorCard
        title="Final Fee Calculator"
        onReset={handleReset}
        content={
          <>
            <Grid.Row>
              <Grid.Col>
                <Label light>Calculation type</Label>
                <Dropdown
                  options={calculationTypes}
                  value={formValues.type}
                  onChange={handleChangeType}
                />
              </Grid.Col>
            </Grid.Row>

            {renderSales()}

            {renderCosts()}

            {renderListing()}

            {renderPayment()}

            {renderUpgrades()}
          </>
        }
        footer={<Button disabled={!formValid} label="Calculate" onClick={handleCalculate} />}
      />

      {!!eBayFee && <ResultCard style={{ marginTop: 25 }} content={renderResult()} />}
    </>
  )
}

export default FinalFee
