import React, { useState } from 'react'
import styled from 'styled-components'

import { Button, Grid, Input, Dropdown, Label, Separator } from '@/style-guide'

import { useFormCalculator } from '@/hooks/useFormCalculator'

import { number } from '@/helpers/validations'

import { CalculatorCard } from '../../components'
import { calculateSalePaypal, OPERATIONS } from './calculations'
import { OPTIONS_COUNTRIES, OPTIONS_OPERATIONS, OPTIONS_FEES } from './dropdownOptions'

const StyledDropdown = styled(Dropdown)`
  flex: 1;
  margin-left: auto;
`

const initialValues = {
  feeValue: '',
  feePercentage: '',
  totalValue: ''
}

const PayPal = () => {
  const [finalValues, setFinalValues] = useState(initialValues)

  const { formValid, formValues, resetValues, changeValue } = useFormCalculator({
    values: {
      value: '',
      operation: OPTIONS_OPERATIONS[0],
      country: OPTIONS_COUNTRIES[0],
      feeRate: OPTIONS_FEES[0].options[0]
    },
    requiredKeys: ['value'],
    validation: {
      value: number.positiveNumber
    }
  })

  const handleReset = () => {
    resetValues()
    setFinalValues(initialValues)
  }

  const handleChangeOperation = value => {
    changeValue('operation', value)
    setFinalValues(initialValues)
  }

  const handleCalculate = () => {
    const { feeValue, feePercentage, totalValue } = calculateSalePaypal(formValues)
    setFinalValues({ feeValue, feePercentage, totalValue })
  }

  const renderMessages = () => {
    const { feeValue, totalValue, feePercentage } = finalValues

    if (formValues.operation.value === OPERATIONS.SENDING) {
      return (
        <Separator>
          <span>
            If sending <strong>US${formValues.value}</strong>, the amount that will go to fees is{' '}
            <strong>US${feeValue}</strong> and the recipient will receive{' '}
            <strong>US${totalValue}</strong>. That&apos;s <strong>{feePercentage}%</strong> going to
            fees, effectively.
          </span>
        </Separator>
      )
    }

    return (
      <Separator>
        <span>
          To receive <strong>US${formValues.value}</strong>, a payment of{' '}
          <strong>US${totalValue}</strong> should be sent. <strong>US${feeValue}</strong> will go to
          fees. That&apos;s <strong>{feePercentage}%</strong> in fees.
        </span>
      </Separator>
    )
  }

  return (
    <CalculatorCard
      title="Sale Paypal"
      onReset={handleReset}
      content={
        <Grid>
          <Grid.Row>
            <Grid.Col>
              <Input
                type="number"
                label="Enter payment amount below"
                value={formValues.value}
                onChange={value => changeValue('value', value)}
                action={
                  <Dropdown
                    value={formValues.operation}
                    options={OPTIONS_OPERATIONS}
                    onChange={handleChangeOperation}
                  />
                }
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <Label>Country or Region (currency)</Label>
              <StyledDropdown
                value={formValues.country}
                options={OPTIONS_COUNTRIES}
                onChange={value => changeValue('country', value)}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <Label>Fee Rate</Label>
              <StyledDropdown
                value={formValues.feeRate}
                options={OPTIONS_FEES}
                onChange={value => changeValue('feeRate', value)}
              />
            </Grid.Col>
          </Grid.Row>

          {!!finalValues.feeValue && renderMessages()}
        </Grid>
      }
      footer={<Button disabled={!formValid} label="Calculate" onClick={handleCalculate} />}
    />
  )
}

export default PayPal
