export const UPGRADE_VALUE_REFERENCE = 150

export const CATEGORIES_TYPES = {
  BOOKS: 1,
  CAMERA: 2,
  CLOTHING: 3,
  COINS: 4,
  COLLECTIBLES: 5,
  COMPUTER: 6,
  CONSUMER: 7,
  MOTORS: 8,
  MUSICAL: 9,
  GUITARS: 10,
  HEAVY_EQUIPMENT: 11,
  OTHER: 12
}

export const AUCTION_UPGRADES_TYPES = {
  DAY_DURATION: 1,
  BOLD: 2,
  GALLERY_PLUS: 3,
  TWO_CATEGORIES: 4,
  LISTING_DESIGNER: 5,
  SUBTITLE: 6,
  INTERNATIONAL_SITE: 7
}

export const FIXED_UPGRADES_TYPES = {
  BOLD: 1,
  GALLERY_PLUS: 2,
  INTERNATIONAL_SITE: 3,
  TWO_CATEGORIES: 4,
  LISTING_DESIGNER: 5,
  SCHEDULED_LISTING: 6,
  SUBTITLE: 7
}

export const STORE_TYPES = {
  NONE: 'None',
  STARTER: 'Starter',
  BASIC: 'Basic',
  PREMIUM: 'Premium',
  ANCHOR: 'Anchor',
  ENTERPRISE: 'Enterprise'
}

export const TYPES = {
  AUCTION: 'Auction',
  FIXED_PRICE: 'Fixed'
}

const STORE_LIMITS = {
  [STORE_TYPES.STARTER]: 750,
  [STORE_TYPES.BASIC]: 350,
  [STORE_TYPES.PREMIUM]: 350,
  [STORE_TYPES.ANCHOR]: 250,
  [STORE_TYPES.ENTERPRISE]: 250
}

export const AUCTION_UPGRADES = [
  {
    label: '1-3-Day Duration',
    type: AUCTION_UPGRADES_TYPES.DAY_DURATION,
    fees: {
      higher: 1,
      lower: 1
    }
  },
  {
    label: 'Bold',
    type: AUCTION_UPGRADES_TYPES.BOLD,
    fees: {
      higher: 3,
      lower: 2
    }
  },
  {
    label: 'Gallery Plus',
    type: AUCTION_UPGRADES_TYPES.GALLERY_PLUS,
    fees: {
      higher: 0.7,
      lower: 0.35
    }
  },
  {
    label: '2 Categories',
    type: AUCTION_UPGRADES_TYPES.TWO_CATEGORIES,
    fees: {
      higher: 0,
      lower: 0
    }
  },
  {
    label: 'Listing Designer',
    type: AUCTION_UPGRADES_TYPES.LISTING_DESIGNER,
    fees: {
      higher: 0.2,
      lower: 0.1
    }
  },
  {
    label: 'Subtitle',
    type: AUCTION_UPGRADES_TYPES.SUBTITLE,
    fees: {
      higher: 3,
      lower: 1
    }
  },
  {
    label: 'International Site Visibility',
    type: AUCTION_UPGRADES_TYPES.INTERNATIONAL_SITE,
    fees: value => {
      switch (value) {
        case value <= 9.99 && value >= 0.01:
          return 0.1
        case value <= 49.99:
          return 0.2
        default:
          return 0.4
      }
    }
  }
]

export const FIXED_UPGRADES = [
  {
    label: 'Bold',
    type: FIXED_UPGRADES_TYPES.BOLD,
    fees: {
      higher: 6,
      lower: 4
    }
  },
  {
    label: 'Gallery Plus',
    type: FIXED_UPGRADES_TYPES.GALLERY_PLUS,
    fees: {
      higher: 2,
      lower: 1
    }
  },
  {
    label: 'International Site',
    type: FIXED_UPGRADES_TYPES.INTERNATIONAL_SITE,
    fees: {
      higher: 0.5,
      lower: 0.5
    }
  },
  {
    label: '2 Categories',
    type: FIXED_UPGRADES_TYPES.TWO_CATEGORIES,
    fees: {
      higher: 0,
      lower: 0
    }
  },
  {
    label: 'Listing Designer',
    type: FIXED_UPGRADES_TYPES.LISTING_DESIGNER,
    fees: {
      higher: 0.6,
      lower: 0.3
    }
  },
  {
    label: 'Scheduled Listing',
    type: FIXED_UPGRADES_TYPES.SCHEDULED_LISTING,
    fees: {
      higher: 0,
      lower: 0
    }
  },
  {
    label: 'Subtitle',
    type: FIXED_UPGRADES_TYPES.SUBTITLE,
    fees: {
      higher: 6,
      lower: 1.5
    }
  }
]

export const CATEGORIES = [
  {
    label: 'Books, DVDs & Movies, Music',
    type: CATEGORIES_TYPES.BOOKS,
    fees: {
      insertion: 0.35,
      finalValue: {
        percentage: 12,
        maximumFee: 750
      },
      stores: {
        [STORE_TYPES.STARTER]: {
          percentage: 12,
          maximumFee: STORE_LIMITS[STORE_TYPES.STARTER]
        },
        [STORE_TYPES.BASIC]: {
          percentage: 12,
          maximumFee: STORE_LIMITS[STORE_TYPES.BASIC]
        },
        [STORE_TYPES.PREMIUM]: {
          percentage: 12,
          maximumFee: STORE_LIMITS[STORE_TYPES.PREMIUM]
        },
        [STORE_TYPES.ANCHOR]: {
          percentage: 12,
          maximumFee: STORE_LIMITS[STORE_TYPES.ANCHOR]
        },
        [STORE_TYPES.ENTERPRISE]: {
          percentage: 12,
          maximumFee: STORE_LIMITS[STORE_TYPES.ENTERPRISE]
        }
      }
    }
  },
  {
    label: 'Camera & Photo Accessories, Cell Phone Accessories and more',
    type: CATEGORIES_TYPES.CAMERA,
    fees: {
      insertion: 0.35,
      finalValue: {
        percentage: 10,
        maximumFee: 750
      },
      stores: {
        [STORE_TYPES.STARTER]: {
          percentage: 10,
          maximumFee: STORE_LIMITS[STORE_TYPES.STARTER]
        },
        [STORE_TYPES.BASIC]: {
          percentage: 6.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.BASIC]
        },
        [STORE_TYPES.PREMIUM]: {
          percentage: 6.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.PREMIUM]
        },
        [STORE_TYPES.ANCHOR]: {
          percentage: 6.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.ANCHOR]
        },
        [STORE_TYPES.ENTERPRISE]: {
          percentage: 6.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.ENTERPRISE]
        }
      }
    }
  },
  {
    label: 'Clothing, Shoes & Accessories',
    type: CATEGORIES_TYPES.CLOTHING,
    fees: {
      insertion: 0.35,
      finalValue: {
        percentage: 10,
        maximumFee: 750
      },
      stores: {
        [STORE_TYPES.STARTER]: {
          percentage: 10,
          maximumFee: STORE_LIMITS[STORE_TYPES.STARTER]
        },
        [STORE_TYPES.BASIC]: {
          percentage: 9.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.BASIC]
        },
        [STORE_TYPES.PREMIUM]: {
          percentage: 9.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.PREMIUM]
        },
        [STORE_TYPES.ANCHOR]: {
          percentage: 9.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.ANCHOR]
        },
        [STORE_TYPES.ENTERPRISE]: {
          percentage: 9.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.ENTERPRISE]
        }
      }
    }
  },
  {
    label: 'Coins & Paper Money, Stamps',
    type: CATEGORIES_TYPES.COINS,
    fees: {
      insertion: 0.35,
      finalValue: {
        percentage: 10,
        maximumFee: 750
      },
      stores: {
        [STORE_TYPES.STARTER]: {
          percentage: 10,
          maximumFee: STORE_LIMITS[STORE_TYPES.STARTER]
        },
        [STORE_TYPES.BASIC]: {
          percentage: 6.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.BASIC]
        },
        [STORE_TYPES.PREMIUM]: {
          percentage: 6.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.PREMIUM]
        },
        [STORE_TYPES.ANCHOR]: {
          percentage: 6.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.ANCHOR]
        },
        [STORE_TYPES.ENTERPRISE]: {
          percentage: 6.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.ENTERPRISE]
        }
      }
    }
  },
  {
    label: 'Collectibles, Art, Pottery & Glass and Antiques',
    type: CATEGORIES_TYPES.COLLECTIBLES,
    fees: {
      insertion: 0.35,
      finalValue: {
        percentage: 10,
        maximumFee: 750
      },
      stores: {
        [STORE_TYPES.STARTER]: {
          percentage: 10,
          maximumFee: STORE_LIMITS[STORE_TYPES.STARTER]
        },
        [STORE_TYPES.BASIC]: {
          percentage: 9.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.BASIC]
        },
        [STORE_TYPES.PREMIUM]: {
          percentage: 9.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.PREMIUM]
        },
        [STORE_TYPES.ANCHOR]: {
          percentage: 9.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.ANCHOR]
        },
        [STORE_TYPES.ENTERPRISE]: {
          percentage: 9.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.ENTERPRISE]
        }
      }
    }
  },
  {
    label: 'Computer/Tablets & Networking, Video Game Consoles',
    type: CATEGORIES_TYPES.COMPUTER,
    fees: {
      insertion: 0.35,
      finalValue: {
        percentage: 10,
        maximumFee: 750
      },
      stores: {
        [STORE_TYPES.STARTER]: {
          percentage: 10,
          maximumFee: STORE_LIMITS[STORE_TYPES.STARTER]
        },
        [STORE_TYPES.BASIC]: {
          percentage: 6.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.BASIC]
        },
        [STORE_TYPES.PREMIUM]: {
          percentage: 6.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.PREMIUM]
        },
        [STORE_TYPES.ANCHOR]: {
          percentage: 6.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.ANCHOR]
        },
        [STORE_TYPES.ENTERPRISE]: {
          percentage: 6.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.ENTERPRISE]
        }
      }
    }
  },
  {
    label: 'Consumer Electronics, Cameras & Photo and more',
    type: CATEGORIES_TYPES.CONSUMER,
    fees: {
      insertion: 0.35,
      finalValue: {
        percentage: 10,
        maximumFee: 750
      },
      stores: {
        [STORE_TYPES.STARTER]: {
          percentage: 10,
          maximumFee: STORE_LIMITS[STORE_TYPES.STARTER]
        },
        [STORE_TYPES.BASIC]: {
          percentage: 6.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.BASIC]
        },
        [STORE_TYPES.PREMIUM]: {
          percentage: 6.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.PREMIUM]
        },
        [STORE_TYPES.ANCHOR]: {
          percentage: 6.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.ANCHOR]
        },
        [STORE_TYPES.ENTERPRISE]: {
          percentage: 6.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.ENTERPRISE]
        }
      }
    }
  },
  {
    label: 'Motors Parts & Accessories',
    type: CATEGORIES_TYPES.MOTORS,
    fees: {
      insertion: 0.35,
      finalValue: {
        percentage: 10,
        maximumFee: 750
      },
      stores: {
        [STORE_TYPES.STARTER]: {
          percentage: 10,
          maximumFee: STORE_LIMITS[STORE_TYPES.STARTER]
        },
        [STORE_TYPES.BASIC]: {
          percentage: 9.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.BASIC]
        },
        [STORE_TYPES.PREMIUM]: {
          percentage: 9.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.PREMIUM]
        },
        [STORE_TYPES.ANCHOR]: {
          percentage: 9.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.ANCHOR]
        },
        [STORE_TYPES.ENTERPRISE]: {
          percentage: 9.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.ENTERPRISE]
        }
      }
    }
  },
  {
    label: 'Musical Instruments & Gear',
    type: CATEGORIES_TYPES.MUSICAL,
    fees: {
      insertion: 0.35,
      finalValue: {
        percentage: 10,
        maximumFee: 750
      },
      stores: {
        [STORE_TYPES.STARTER]: {
          percentage: 3.5,
          maximumFee: STORE_LIMITS[STORE_TYPES.STARTER]
        },
        [STORE_TYPES.BASIC]: {
          percentage: 7.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.BASIC]
        },
        [STORE_TYPES.PREMIUM]: {
          percentage: 7.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.PREMIUM]
        },
        [STORE_TYPES.ANCHOR]: {
          percentage: 7.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.ANCHOR]
        },
        [STORE_TYPES.ENTERPRISE]: {
          percentage: 7.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.ENTERPRISE]
        }
      }
    }
  },
  {
    label: 'Guitars & Basses',
    type: CATEGORIES_TYPES.GUITARS,
    fees: {
      insertion: 0,
      finalValue: {
        percentage: 3.5,
        maximumFee: 350
      },
      stores: {
        [STORE_TYPES.STARTER]: {
          percentage: 3.5,
          maximumFee: STORE_LIMITS[STORE_TYPES.STARTER]
        },
        [STORE_TYPES.BASIC]: {
          percentage: 3.5,
          maximumFee: STORE_LIMITS[STORE_TYPES.BASIC]
        },
        [STORE_TYPES.PREMIUM]: {
          percentage: 3.5,
          maximumFee: STORE_LIMITS[STORE_TYPES.PREMIUM]
        },
        [STORE_TYPES.ANCHOR]: {
          percentage: 3.5,
          maximumFee: STORE_LIMITS[STORE_TYPES.ANCHOR]
        },
        [STORE_TYPES.ENTERPRISE]: {
          percentage: 3.5,
          maximumFee: STORE_LIMITS[STORE_TYPES.ENTERPRISE]
        }
      }
    }
  },
  {
    label: 'Heavy Equipment, Commercial Printing Presses, Food Trucks, Trailers & Carts',
    type: CATEGORIES_TYPES.HEAVY_EQUIPMENT,
    fees: {
      insertion: 20,
      finalValue: {
        percentage: 2,
        maximumFee: 300
      },
      stores: {
        [STORE_TYPES.STARTER]: {
          percentage: 2,
          maximumFee: STORE_LIMITS[STORE_TYPES.STARTER]
        },
        [STORE_TYPES.BASIC]: {
          percentage: 1.5,
          maximumFee: STORE_LIMITS[STORE_TYPES.BASIC]
        },
        [STORE_TYPES.PREMIUM]: {
          percentage: 1.5,
          maximumFee: STORE_LIMITS[STORE_TYPES.PREMIUM]
        },
        [STORE_TYPES.ANCHOR]: {
          percentage: 1.5,
          maximumFee: STORE_LIMITS[STORE_TYPES.ANCHOR]
        },
        [STORE_TYPES.ENTERPRISE]: {
          percentage: 1.5,
          maximumFee: STORE_LIMITS[STORE_TYPES.ENTERPRISE]
        }
      }
    }
  },
  {
    label: 'All other categories',
    type: CATEGORIES_TYPES.OTHER,
    fees: {
      insertion: 0.35,
      finalValue: {
        percentage: 10,
        maximumFee: 750
      },
      stores: {
        [STORE_TYPES.STARTER]: {
          percentage: 10,
          maximumFee: STORE_LIMITS[STORE_TYPES.STARTER]
        },
        [STORE_TYPES.BASIC]: {
          percentage: 9.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.BASIC]
        },
        [STORE_TYPES.PREMIUM]: {
          percentage: 9.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.PREMIUM]
        },
        [STORE_TYPES.ANCHOR]: {
          percentage: 9.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.ANCHOR]
        },
        [STORE_TYPES.ENTERPRISE]: {
          percentage: 9.15,
          maximumFee: STORE_LIMITS[STORE_TYPES.ENTERPRISE]
        }
      }
    }
  }
]

export const STORES = [
  {
    label: STORE_TYPES.NONE,
    type: STORE_TYPES.NONE,
    insertionFee: {
      [TYPES.AUCTION]: 0,
      [TYPES.FIXED_PRICE]: 0
    }
  },
  {
    label: STORE_TYPES.STARTER,
    type: STORE_TYPES.STARTER,
    insertionFee: {
      [TYPES.AUCTION]: 0.3,
      [TYPES.FIXED_PRICE]: 0.3
    }
  },
  {
    label: STORE_TYPES.BASIC,
    type: STORE_TYPES.BASIC,
    insertionFee: {
      [TYPES.AUCTION]: 0.25,
      [TYPES.FIXED_PRICE]: 0.25
    }
  },
  {
    label: STORE_TYPES.PREMIUM,
    type: STORE_TYPES.PREMIUM,
    insertionFee: {
      [TYPES.AUCTION]: 0.15,
      [TYPES.FIXED_PRICE]: 0.1
    }
  },
  {
    label: STORE_TYPES.ANCHOR,
    type: STORE_TYPES.ANCHOR,
    insertionFee: {
      [TYPES.AUCTION]: 0.1,
      [TYPES.FIXED_PRICE]: 0.05
    }
  },
  {
    label: STORE_TYPES.ENTERPRISE,
    type: STORE_TYPES.ENTERPRISE,
    insertionFee: {
      [TYPES.AUCTION]: 0.1,
      [TYPES.FIXED_PRICE]: 0.05
    }
  }
]
