import { toFloat, getFixedResult } from '@/helpers/numbers'

export const OPERATIONS = {
  SENDING: 0,
  RECEIVING: 1
}

const getValuesToReceive = (value, feeRate) => {
  const totalValue =
    (value + toFloat(feeRate.costPerTransaction)) / (1 - toFloat(feeRate.value) / 100)
  const feeValue = toFloat(totalValue - value)
  const feePercentage = (feeValue * 100) / totalValue

  return {
    feeValue: getFixedResult(feeValue),
    totalValue: getFixedResult(totalValue),
    feePercentage: getFixedResult(feePercentage)
  }
}

const getValuesToSend = (value, feeRate) => {
  const feeValue = value * (toFloat(feeRate.value) / 100) + toFloat(feeRate.costPerTransaction)
  const totalValue = value - feeValue
  const feePercentage = (feeValue * 100) / value

  return {
    feeValue: getFixedResult(feeValue),
    totalValue: getFixedResult(totalValue),
    feePercentage: getFixedResult(feePercentage)
  }
}

/**
 *
 * [Sending]
 * feeValue: (value * localFee) + costPerTransaction
 * totalValue: value - feeValue
 * feePercentage: (feeValue * 100) / value
 *
 * [Receiving]
 * totalValue: (value + costPerTransaction) / (1 - (localFee / 100))
 * feeValue: totalValue - value
 * feePercentage: (feeValue * 100) / totalValue *
 *
 */
export const calculateSalePaypal = ({ value, operation, feeRate }) => {
  const floatedValue = toFloat(value)

  if (!floatedValue) {
    return {
      feeValue: 0,
      totalValue: 0,
      feePercentage: 0
    }
  }

  return operation.value === OPERATIONS.SENDING
    ? getValuesToSend(floatedValue, feeRate)
    : getValuesToReceive(floatedValue, feeRate)
}
