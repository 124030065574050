export const OPTIONS_OPERATIONS = [
  {
    key: 'Sending',
    value: 0
  },
  {
    key: 'Receiving',
    value: 1
  }
]

export const OPTIONS_COUNTRIES = [
  {
    key: 'United States (USD)',
    value: 'usa'
  }
]

export const OPTIONS_FEES = [
  {
    key: 'Domestic',
    options: [
      {
        key: '2.9% + $0.30 (via online transaction)',
        value: 2.9,
        costPerTransaction: 0.3
      },
      {
        key: '2.7% + $0.30 (via store location)',
        value: 2.7,
        costPerTransaction: 0.3
      },
      {
        key: '2.2% + $0.30 (Nonprofit)',
        value: 2.2,
        costPerTransaction: 0.3
      },
      {
        key: '5% + $0.05 (Micropayment)',
        value: 5,
        costPerTransaction: 0.05
      }
    ]
  },
  {
    key: 'International',
    options: [
      {
        key: '4.4% + $0.30 (via online transaction)',
        value: 4.4,
        costPerTransaction: 0.3
      },
      {
        key: '4.2% + $0.30 (via store location)',
        value: 4.2,
        costPerTransaction: 0.3
      },
      {
        key: '3.7% + $0.30 (Nonprofit)',
        value: 3.7,
        costPerTransaction: 0.3
      },
      {
        key: '6.5% + $0.05 (Micropayment)',
        value: 6.5,
        costPerTransaction: 0.05
      }
    ]
  },
  {
    key: 'Mobile Card Reader',
    options: [
      {
        key: '2.7% (Swiped & check-in transactions)',
        value: 2.7,
        costPerTransaction: 0
      },
      {
        key: '3.5% + $0.15 (Keyed or scanned transactions)',
        value: 3.5,
        costPerTransaction: 0.15
      }
    ]
  },
  {
    key: 'Virtual Terminal',
    options: [
      {
        key: '3.1% + $0.30',
        value: 3.1,
        costPerTransaction: 0.3
      }
    ]
  }
]
