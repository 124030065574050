import { toFloat, getFixedResult } from '@/helpers/numbers'

import { calculateAuctionStyleFee } from './calculationsAuction'
import { calculateFixedPriceFee } from './calculationsFixedPrice'

import { STORES, TYPES } from './constants'
import { OPTIONS_FEES } from '../PayPal/dropdownOptions'

const getPaypalFee = (costValue, paypalPayment) => {
  const feeValue =
    costValue * (toFloat(paypalPayment.value) / 100) + toFloat(paypalPayment.costPerTransaction)

  return feeValue
}

const getProfit = (costValue, itemCost, shippingCost, eBayFee, paypalFee) =>
  costValue - (itemCost + shippingCost + eBayFee + paypalFee)

const getReturn = (profit, totalFees) => (profit / totalFees) * 100

export const calculateFees = ({
  type,
  values,
  freeInsertionFee,
  category,
  paypalPayment = OPTIONS_FEES[0].options[0],
  upgrades = [],
  store = STORES[0]
}) => {
  const { closingPrice, buyItNowPrice, shippingPrice, itemCost, shippingCost } = values
  const isAuction = type === TYPES.AUCTION
  const costValue = (isAuction ? closingPrice : buyItNowPrice) + shippingPrice

  const paypalFee = getPaypalFee(costValue, paypalPayment)

  const eBayFee = isAuction
    ? calculateAuctionStyleFee({
        values,
        freeInsertionFee,
        category,
        upgrades,
        store
      })
    : calculateFixedPriceFee({
        values,
        freeInsertionFee,
        category,
        upgrades,
        store
      })

  const profitValue = getProfit(costValue, itemCost, shippingCost, eBayFee, paypalFee)

  const totalFees = paypalFee + eBayFee + itemCost + shippingCost
  const returnValue = getReturn(profitValue, totalFees)

  return {
    eBayFee: toFloat(getFixedResult(eBayFee)),
    paypalFee: toFloat(getFixedResult(paypalFee)),
    profitValue: toFloat(getFixedResult(profitValue)),
    returnValue: toFloat(getFixedResult(returnValue))
  }
}
