import React, { useState } from 'react'
import { Input, Grid, Button, Separator } from '@/style-guide'

import { useFormCalculator } from '@/hooks/useFormCalculator'
import { number } from '@/helpers/validations'

import { CalculatorCard } from '../../components'
import { calculateStripeFees } from './calculations'

const initialValues = {
  totalFees: '',
  valueToReceive: '',
  valueToAsk: '',
  valueToAskFee: ''
}

const Stripe = () => {
  const [finalValues, setFinalValues] = useState(initialValues)

  const { formValid, formValues, resetValues, changeValue } = useFormCalculator({
    values: {
      value: ''
    },
    requiredKeys: ['value'],
    validation: {
      value: number.positiveNumber
    }
  })

  const handleCalculate = () => {
    const { totalFees, valueToReceive, valueToAsk, valueToAskFee } = calculateStripeFees(
      formValues.value
    )
    setFinalValues({ totalFees, valueToReceive, valueToAsk, valueToAskFee })
  }

  const renderResult = () => {
    const { totalFees, valueToReceive, valueToAsk, valueToAskFee } = finalValues

    return (
      <Separator>
        <span>
          If you are paid the exact Amout entered, you will receive:{' '}
          <strong>US${valueToReceive}</strong>.<br /> Stripe Fee: <strong>US${totalFees}</strong>.
          <br /> If you want to receive the exact Amount entered, you should ask for{' '}
          <strong>US${valueToAsk}</strong>.<br /> Stripe Fee: <strong>US${valueToAskFee}</strong>
        </span>
      </Separator>
    )
  }

  const handleReset = () => {
    setFinalValues(initialValues)
    resetValues()
  }

  return (
    <CalculatorCard
      title="Stripe"
      onReset={handleReset}
      content={
        <Grid>
          <Grid.Row>
            <Grid.Col>
              <Input
                type="number"
                label="Enter an invoice amount"
                value={formValues.value}
                onChange={value => changeValue('value', value)}
              />
            </Grid.Col>
          </Grid.Row>

          {!!finalValues.totalFees && renderResult()}
        </Grid>
      }
      footer={<Button disabled={!formValid} label="Calculate" onClick={handleCalculate} />}
    />
  )
}

export default Stripe
